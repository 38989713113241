import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { VariantFormState, VariantFormStates } from '@mid-react-common/common';
import { configureAndSelectTabs, ConfigureAndSelectTabs } from '@mid-react-common/revit-components';
import { screens, Screens } from 'context/Navigation/navigationStore';
import { DCProductUIExtension } from 'mid-types';

interface UseHeaderProps {
  setCurrentScreen: (screen: Screens) => void;
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined) => void;
  setSelectedProductCustomizationTab: (tab: ConfigureAndSelectTabs) => void;
  setSelectedCachedVariant: (selectedCachedVariant: Variant | null) => void;
  setCachedVariantsList: (cachedVariantsList: Variant[]) => void;
  setReFetchCachedVariants: (reFetchCachedVariants: boolean) => void;
  setVariantFormState: (variantFormState: VariantFormStates) => void;
}

interface UseHeaderState {
  handleChangeProductClick: () => void;
}

const useHeader = ({
  setCurrentScreen,
  setCurrentProductRelease,
  setSelectedProductCustomizationTab,
  setSelectedCachedVariant,
  setCachedVariantsList,
  setReFetchCachedVariants,
  setVariantFormState,
}: UseHeaderProps): UseHeaderState => {
  const handleChangeProductClick = () => {
    setCurrentScreen(screens.PRODUCT_SELECTION);
    setCurrentProductRelease(undefined);
    setSelectedProductCustomizationTab(configureAndSelectTabs.TAB_CONFIGURE);

    setSelectedCachedVariant(null);
    setCachedVariantsList([]);
    setReFetchCachedVariants(true);
    setVariantFormState(VariantFormState.DEFAULT_VARIANT);
  };

  return { handleChangeProductClick };
};

export default useHeader;
