import { useShallow } from 'zustand/react/shallow';
import { useAddinVersion } from '@mid-react-common/addins';
import { AboutInfoMenu, TopHeader } from '@mid-react-common/common';
import React from 'react';
import { navigationStoreActions, screens, useNavigationStore } from 'context/Navigation/navigationStore';
import CustomizeProduct from './SubHeaders/CustomizeProduct';
import Initial from './SubHeaders/Initial';
import { dataStoreActions } from 'context/Data/dataStore';
import useHeader from './useHeader';

const Header: React.FC = (): JSX.Element => {
  const { currentScreen } = useNavigationStore(
    useShallow((state) => ({
      currentScreen: state.currentScreen,
    })),
  );
  const { setCurrentScreen, setSelectedProductCustomizationTab } = navigationStoreActions;

  const { addinVersion, desktopApplicationVersion } = useAddinVersion();
  const {
    setSelectedCachedVariant,
    setCachedVariantsList,
    setReFetchCachedVariants,
    setCurrentProductRelease,
    setVariantFormState,
  } = dataStoreActions;

  const { handleChangeProductClick } = useHeader({
    setCurrentScreen,
    setCurrentProductRelease,
    setSelectedProductCustomizationTab,
    setSelectedCachedVariant,
    setCachedVariantsList,
    setReFetchCachedVariants,
    setVariantFormState,
  });

  return (
    <TopHeader>
      {currentScreen === screens.PRODUCT_SELECTION && <Initial />}
      {currentScreen === screens.PRODUCT_CUSTOMIZATION && (
        <CustomizeProduct handleChangeProductClick={handleChangeProductClick} />
      )}
      <AboutInfoMenu
        isTargetBlank
        webComponentVersion={import.meta.env.VITE_RVTW_VERSION || ''}
        addinVersion={addinVersion}
        desktopApplicationVersion={desktopApplicationVersion}
      />
    </TopHeader>
  );
};

export default Header;
