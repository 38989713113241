import { ProductRelease } from '@adsk/offsite-dc-sdk';
import {
  analytics,
  ANALYTICS_ACTIONS,
  NOTIFICATION_STATUSES,
  NotificationContext,
  RevitWebComponentMoniker,
} from '@mid-react-common/common';
import { Screens, screens } from 'context/Navigation/navigationStore';
import { ProductsUtils, getDesktopApplicationVersionNumber } from 'mid-addin-lib';
import { CustomError, ProductReleaseError, logError } from 'mid-utils';
import { useContext, useState } from 'react';
import text from 'revit.text.json';
import { AccBridgeDownloadUrlQueryParams, AllAccBridgeQueryParams } from 'mid-api-services';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DCProductUIExtension } from 'mid-types';
import { ConfigureAndSelectTabs, configureAndSelectTabs } from '@mid-react-common/revit-components';

interface UseInitialProps {
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  selectedIncomingBridgeDataQueryParams: AllAccBridgeQueryParams | undefined;
  sessionId: string;
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined) => void;
  setCurrentScreen: (screen: Screens) => void;
  setSelectedProductCustomizationTab: (tab: ConfigureAndSelectTabs) => void;
}

interface UseInitialState {
  productReleaseEnhancementWithRulesLoading: boolean;
  handleOpenProductClick: () => void;
}

export const useInitial = ({
  currentProductRelease,
  selectedIncomingBridgeDataQueryParams,
  sessionId,
  setCurrentProductRelease,
  setCurrentScreen,
  setSelectedProductCustomizationTab,
}: UseInitialProps): UseInitialState => {
  const { showNotification } = useContext(NotificationContext);
  const { enableStaticContent } = useFlags();

  const [productReleaseEnhancementWithRulesLoading, setProductReleaseEnhancementWithRulesLoading] = useState(false);

  const handleOpenProductClick = async () => {
    try {
      setProductReleaseEnhancementWithRulesLoading(true);
      if (!currentProductRelease) {
        throw new ProductReleaseError(text.newVariantMissingSelectedProductRelease, {
          currentProductRelease,
        });
      }

      // Validate and load rules before opening product
      await checkApplicationVersion(currentProductRelease);
      const incomingAccBridgeDataForDownloadUrl: AccBridgeDownloadUrlQueryParams = {
        sourceProjectId: selectedIncomingBridgeDataQueryParams?.sourceProjectId,
        targetProjectId: selectedIncomingBridgeDataQueryParams?.targetProjectId,
      };
      const productRules = await ProductsUtils.checkAndDownloadProductRulesFromKey({
        tenancyId: currentProductRelease.tenancyId,
        rulesKey: currentProductRelease.rulesKey,
        incomingAccBridgeData: incomingAccBridgeDataForDownloadUrl,
      });

      // If product has rules downloaded with rulesKey, update rules property
      if (productRules) {
        setCurrentProductRelease({ ...currentProductRelease, rules: productRules });
      }

      // Segment event
      analytics.track(ANALYTICS_ACTIONS.RVTW.openProduct, {
        session_id: sessionId,
        sourceMoniker: RevitWebComponentMoniker,
        productName: currentProductRelease.name,
        releaseNumber: currentProductRelease.release,
        projectId: currentProductRelease.tenancyId,
        productId: currentProductRelease.contentId,
        hasRules: !!currentProductRelease.rules,
        numberOfInputs: currentProductRelease.inputs.length,
        releaseStatus: currentProductRelease.status,
      });

      if (enableStaticContent) {
        const isProductReleaseConfigurable = currentProductRelease?.isConfigurable !== false;
        // If product is not configurable (Static), go straight to tab to Select, otherwise go to Configure
        setSelectedProductCustomizationTab(
          !isProductReleaseConfigurable ? configureAndSelectTabs.TAB_SELECT : configureAndSelectTabs.TAB_CONFIGURE,
        );
      }

      // If succeeded go to next screen
      setCurrentScreen(screens.PRODUCT_CUSTOMIZATION);
    } catch (err) {
      if (err instanceof CustomError) {
        logError(err);
        showNotification({
          message: err.message,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        logError(err);
        showNotification({
          message: text.failedToLoadProduct,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    } finally {
      setProductReleaseEnhancementWithRulesLoading(false);
    }
  };

  const checkApplicationVersion = async (currentProductRelease: ProductRelease): Promise<void> => {
    const applicationVersionNumber = await getDesktopApplicationVersionNumber();
    if (
      applicationVersionNumber !== undefined &&
      parseInt(currentProductRelease.context.engine.version) > parseInt(applicationVersionNumber)
    ) {
      throw new ProductReleaseError(text.incompatibleProduct, {
        currentProductRelease,
      });
    }
  };

  return {
    productReleaseEnhancementWithRulesLoading,
    handleOpenProductClick,
  };
};
