import { configureAndSelectTabs, ConfigureAndSelectTabs } from '@mid-react-common/revit-components';
import { create } from 'zustand';

export const screens = {
  PRODUCT_SELECTION: 'PRODUCT_SELECTION',
  PRODUCT_CUSTOMIZATION: 'PRODUCT_CUSTOMIZATION',
} as const;
export type Screens = (typeof screens)[keyof typeof screens];

export interface NavigationStore {
  currentScreen: Screens;
  selectedProductCustomizationTab: ConfigureAndSelectTabs;
}

export const useNavigationStore = create<NavigationStore>(() => ({
  currentScreen: screens.PRODUCT_SELECTION,
  selectedProductCustomizationTab: configureAndSelectTabs.TAB_CONFIGURE,
}));

export const navigationStoreActions = {
  setCurrentScreen: (screen: Screens): void => useNavigationStore.setState({ currentScreen: screen }),
  setSelectedProductCustomizationTab: (tab: ConfigureAndSelectTabs): void =>
    useNavigationStore.setState({ selectedProductCustomizationTab: tab }),
};
