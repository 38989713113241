import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { ProductSelectionScreen } from '../../screens/ProductSelectionScreen/ProductSelectionScreen';
import { ConfigureScreen } from '../../screens/VariantScreens/ConfigureScreen/ConfigureScreen';
import SelectScreen from '../../screens/VariantScreens/SelectScreen/SelectScreen';
import { screens, useNavigationStore } from 'context/Navigation/navigationStore';
import { configureAndSelectTabs } from '@mid-react-common/revit-components';

export const ScreenManager: React.FC = (props) => {
  const { currentScreen, selectedProductCustomizationTab } = useNavigationStore(
    useShallow((state) => ({
      currentScreen: state.currentScreen,
      selectedProductCustomizationTab: state.selectedProductCustomizationTab,
    })),
  );

  if (currentScreen === screens.PRODUCT_SELECTION) {
    return <ProductSelectionScreen {...props} />;
  }
  if (currentScreen === screens.PRODUCT_CUSTOMIZATION) {
    if (selectedProductCustomizationTab === configureAndSelectTabs.TAB_CONFIGURE) {
      return <ConfigureScreen {...props} />;
    }
    return <SelectScreen />;
  }
  return <ProductSelectionScreen {...props} />;
};
