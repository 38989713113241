import { create } from 'zustand';
import { ProductRelease, DCInput, Variant, ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties, VariantFormState, VariantFormStates } from '@mid-react-common/common';
import { AllAccBridgeQueryParams } from 'mid-api-services';
import { DCProductUIExtension } from 'mid-types';
import { getUUID, isDCRfaOutput } from 'mid-utils';

export interface DataStore {
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  configurableProductProperties: ConfigurableProductProperties;
  productReleaseInputsMap: Map<string, DCInput>;
  selectedCachedVariant: Variant | null;
  variantFormState: VariantFormStates;
  selectedVariantThumbnailVisible: boolean;
  reFetchCachedVariants: boolean;
  cachedVariantsList: Variant[];
  selectedRepresentation: string | undefined;
  productReleasesListLength: number | undefined;
  nonObsoleteProductReleasesList: ProductRelease[] | undefined;
  postedVariantIdsList: string[];
  selectedIncomingBridgeDataQueryParams: AllAccBridgeQueryParams | undefined;
  isFormInitializing: boolean;
  sessionId: string;
}

export interface DataStoreActions {
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined) => void;
  updateConfigurableProductInput: (inputToUpdate: DCInput) => void;
  resetConfigurableProductProperties: () => void;
  initConfigurableProductProperties: () => void;
  updateConfigurableProductInputs: (inputsToUpdate: DCInput[]) => void;
  setSelectedCachedVariant: (selectedCachedVariant: Variant | null) => void;
  setVariantFormState: (variantFormState: VariantFormStates) => void;
  setSelectedVariantThumbnailVisible: (isSelectedVariantThumbnailVisible: boolean) => void;
  setReFetchCachedVariants: (reFetchCachedVariants: boolean) => void;
  setCachedVariantsList: (cachedVariantsList: Variant[]) => void;
  setSelectedRepresentation: (selectedRepresentation: string | undefined) => void;
  setProductReleasesList: (productReleasesList: ProductRelease[] | undefined) => void;
  addToPostedVariantIdsList: (postedVariantId: string) => void;
  setSelectedIncomingBridgeDataQueryParams: (
    selectedIncomingBridgeDataQueryParams: AllAccBridgeQueryParams | undefined,
  ) => void;
  setIsFormInitializing: (isFormInitializing: boolean) => void;
}

export const useDataStore = create<DataStore>(() => ({
  currentProductRelease: undefined,
  configurableProductProperties: {
    inputs: [],
    outputs: [],
  } as ConfigurableProductProperties,
  productReleaseInputsMap: new Map<string, DCInput>(),
  selectedCachedVariant: null,
  variantFormState: VariantFormState.DEFAULT_VARIANT,
  selectedVariantThumbnailVisible: true,
  reFetchCachedVariants: true,
  cachedVariantsList: [],
  selectedRepresentation: undefined,
  productReleasesListLength: undefined,
  nonObsoleteProductReleasesList: undefined,
  postedVariantIdsList: [],
  selectedIncomingBridgeDataQueryParams: undefined,
  isFormInitializing: true,
  sessionId: getUUID(),
}));

export const dataStoreActions: DataStoreActions = {
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined): void => {
    useDataStore.setState({ currentProductRelease });
    dataStoreActions.initConfigurableProductProperties();
  },
  updateConfigurableProductInput: (inputToUpdate: DCInput): void => {
    const { productReleaseInputsMap } = useDataStore.getState();
    const clonedInputsMap = new Map<string, DCInput>(productReleaseInputsMap);
    clonedInputsMap.set(inputToUpdate.name, inputToUpdate);

    useDataStore.setState((state) => ({
      productReleaseInputsMap: clonedInputsMap,
      configurableProductProperties: {
        ...state.configurableProductProperties,
        inputs: [...clonedInputsMap.values()],
      },
    }));
  },
  resetConfigurableProductProperties: (): void => {
    const { currentProductRelease } = useDataStore.getState();
    const rfaOutput = currentProductRelease?.outputs.find(isDCRfaOutput);

    if (currentProductRelease) {
      useDataStore.setState({
        configurableProductProperties: {
          inputs: currentProductRelease.inputs,
          outputs: currentProductRelease.outputs,
        },
        productReleaseInputsMap: new Map<string, DCInput>(currentProductRelease.inputs.map((input) => [input.name, input])),
        selectedRepresentation: rfaOutput?.options?.modelStates?.at(0),
      });
    }
  },
  initConfigurableProductProperties() {
    dataStoreActions.resetConfigurableProductProperties();
  },
  updateConfigurableProductInputs: (inputsToUpdate: DCInput[]): void => {
    const inputMaps = inputsToUpdate.reduce((acc: Map<string, DCInput>, input: DCInput) => {
      acc.set(input.name, input);
      return acc;
    }, new Map<string, DCInput>());

    useDataStore.setState((state) => ({
      productReleaseInputsMap: inputMaps,
      configurableProductProperties: {
        ...state.configurableProductProperties,
        inputs: [...inputMaps.values()],
      },
    }));
  },
  setSelectedCachedVariant: (selectedCachedVariant: Variant | null): void =>
    useDataStore.setState({ selectedCachedVariant }),
  setVariantFormState: (variantFormState: VariantFormStates): void => useDataStore.setState({ variantFormState }),
  setSelectedVariantThumbnailVisible: (isSelectedVariantThumbnailVisible: boolean): void =>
    useDataStore.setState({ selectedVariantThumbnailVisible: isSelectedVariantThumbnailVisible }), // TODO change to isSelectedVariantThumbnailVisible
  setReFetchCachedVariants: (reFetchCachedVariants: boolean): void => useDataStore.setState({ reFetchCachedVariants }),
  setCachedVariantsList: (cachedVariantsList: Variant[]): void => useDataStore.setState({ cachedVariantsList }),
  setSelectedRepresentation: (selectedRepresentation: string | undefined): void =>
    useDataStore.setState({ selectedRepresentation }),
  setProductReleasesList: (productReleasesList: ProductRelease[] | undefined): void =>
    useDataStore.setState({
      nonObsoleteProductReleasesList: productReleasesList?.filter((release) => release.status !== ReleaseStatus.OBSOLETE),
      productReleasesListLength: productReleasesList?.length,
    }),
  addToPostedVariantIdsList: (postedVariantId: string): void => {
    const { postedVariantIdsList } = useDataStore.getState();
    useDataStore.setState({ postedVariantIdsList: [...postedVariantIdsList, postedVariantId] });
  },
  setSelectedIncomingBridgeDataQueryParams: (
    selectedIncomingBridgeDataQueryParams: AllAccBridgeQueryParams | undefined,
  ): void => useDataStore.setState({ selectedIncomingBridgeDataQueryParams }),
  setIsFormInitializing: (isFormInitializing: boolean): void => useDataStore.setState({ isFormInitializing }),
};
