import {
  LeftSection,
  RightButton,
  RightSection,
  SpinnerForButton,
  Title,
  midTheme as theme,
} from '@mid-react-common/common';
import text from 'revit.text.json';
import { productSelectionTestIds } from '../../../tests/helpers/dataTestIds';
import { useInitial } from './useInitial';
import { dataStoreActions, useDataStore } from 'context/Data/dataStore';
import { useShallow } from 'zustand/react/shallow';
import { navigationStoreActions } from 'context/Navigation/navigationStore';

const Initial: React.FC = (): JSX.Element => {
  const { currentProductRelease, selectedIncomingBridgeDataQueryParams, sessionId } = useDataStore(
    useShallow((state) => ({
      currentProductRelease: state.currentProductRelease,
      selectedIncomingBridgeDataQueryParams: state.selectedIncomingBridgeDataQueryParams,
      sessionId: state.sessionId,
    })),
  );
  const { setCurrentProductRelease } = dataStoreActions;
  const { setCurrentScreen, setSelectedProductCustomizationTab } = navigationStoreActions;

  const { productReleaseEnhancementWithRulesLoading, handleOpenProductClick } = useInitial({
    currentProductRelease,
    selectedIncomingBridgeDataQueryParams,
    sessionId,
    setCurrentProductRelease,
    setCurrentScreen,
    setSelectedProductCustomizationTab,
  });

  return (
    <>
      <LeftSection>
        <Title variant="h1">{text.subHeaderTitleInitial}</Title>
      </LeftSection>
      <RightSection>
        <RightButton
          data-testid={productSelectionTestIds.openSelectedProductReleaseButton}
          variant="contained"
          size="small"
          disabled={!currentProductRelease?.contentId}
          onClick={handleOpenProductClick}
          upperCase
        >
          {text.openProduct}
          {productReleaseEnhancementWithRulesLoading && (
            <SpinnerForButton size={theme.var.buttonSpinnerSize} color="inherit" />
          )}
        </RightButton>
      </RightSection>
    </>
  );
};

export default Initial;
