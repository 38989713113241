import { AddinContainer, EnvironmentInfo } from '@mid-react-common/addins';
import {
  AppLoadingProgress,
  ConfirmationModal,
  ErrorBoundary,
  ErrorFallback,
  ModalContext,
  NotificationsProvider,
  QueryClientProvider,
  RevitWebComponentMoniker,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  darkTheme,
  lightTheme,
  useModalStore,
  useNotificationStore,
} from '@mid-react-common/common';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { browserApiService, getCurrentAuthToken, getEnvironment } from 'mid-addin-lib';
import { InversifyTypes, inversifyContainer, registerAuthHandler, registerEnv, registerApiBaseURL } from 'mid-api-services';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';
import React, { useEffect, useState } from 'react';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import { useDataStore, dataStoreActions } from 'context/Data/dataStore';
import { useShallow } from 'zustand/react/shallow';

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  moniker: RevitWebComponentMoniker,
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY_ADDINS || '',
  appVersion: import.meta.env.VITE_RVTW_VERSION || 'Not provided',
});

const App: React.FC = (): JSX.Element => {
  const { enableMaintenanceMode, enableDarkModeSwitch, revitWebComponentUnderMaintenance } = useFlags();
  const { currentProductRelease, sessionId } = useDataStore(
    useShallow((state) => ({
      currentProductRelease: state.currentProductRelease,
      sessionId: state.sessionId,
    })),
  );
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();
  const [dark, setDark] = useState<boolean>(false);
  const [apiServicesInitialized, setApiServicesInitialized] = useState(false);

  useEffect(() => {
    if (currentProductRelease) {
      dataStoreActions.initConfigurableProductProperties();
    }
  }, [currentProductRelease]);

  useEffect(() => {
    const registerDependency = async () => {
      const env = await getEnvironment();

      const dcApiBaseURL = await browserApiService.getDcApiUrl();
      const forgeApiBaseUrl = ServiceConfigMap[ServiceTypes.FORGE_API][env];
      const accBridgeBaseUrl = ServiceConfigMap[ServiceTypes.ACC_BRIDGE][env];

      if (!inversifyContainer.isBound(InversifyTypes.Env)) {
        registerEnv(env);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
        registerAuthHandler(() => getCurrentAuthToken());
      }

      if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.DcApiBaseURL, dcApiBaseURL);
      }

      if (!inversifyContainer.isBound(InversifyTypes.ForgeApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.ForgeApiBaseURL, forgeApiBaseUrl.api);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AccBridgeApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.AccBridgeApiBaseURL, accBridgeBaseUrl.api);
      }

      setApiServicesInitialized(true);
    };
    registerDependency();
  }, []);

  const handleResetAppState = (): void => window.location.reload();

  const handleThemeChange = () => {
    setDark((state) => !state);
  };

  const appTree = (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />
      <ModalContext.Provider value={modalStore}>
        <ConfirmationModal isAddin />
        <AddinContainer>
          <NotificationsProvider store={notificationStore}>
            <QueryClientProvider>
              {apiServicesInitialized && sessionId ? (
                <UserAnalyticsProvider webComponentMoniker={RevitWebComponentMoniker} sessionId={sessionId}>
                  {enableMaintenanceMode || revitWebComponentUnderMaintenance ? (
                    <UnderMaintenance />
                  ) : (
                    <>
                      {enableDarkModeSwitch && (
                        <Switch
                          onChange={handleThemeChange}
                          sx={{ position: 'absolute', top: 0, left: '50%', zIndex: 1000 }}
                        />
                      )}
                      <Header />
                      <Divider />
                      <EnvironmentInfo hostname={window.location.host} />
                      <ScreenManager />
                    </>
                  )}
                </UserAnalyticsProvider>
              ) : (
                <AppLoadingProgress />
              )}
            </QueryClientProvider>
          </NotificationsProvider>
        </AddinContainer>
      </ModalContext.Provider>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
